import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { logoutAPI } from "../store/slices/authUserSlice";
import { SvgCaltanaLogo } from "../components/base-components/SVGIcons";

const Custom404Page = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logoutAPI());
    router.replace("/auth/login");
  };
  return (
    <div>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "90%",
          padding: 16,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SvgCaltanaLogo />
        <Box mt={8} fontSize={18}>
          This page is unavailable
        </Box>
        <Box fontSize={12} color="gray" mb={4}>
          You don&apos;t have access or this link doesn&apos;t exist
        </Box>
        <Button variant="contained" href="/pursuits/dashboard" sx={{ mb: 2 }}>
          Go to Home Page
        </Button>
        <Button disableRipple onClick={onLogout}>
          or Sign out
        </Button>
      </Box>
    </div>
  );
};

export default Custom404Page;
